@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.custom-figure {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-gradient {
  position: relative;
}

.custom-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9));
  border-radius: inherit;
  z-index: 1;
}

.custom-figure img {
  display: block;
  width: 100%;
  border-radius: inherit;
}

.custom-figure figcaption {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  /* Ensure text is above the gradient */
}



@media (min-width: 769px) {
  .container {
    max-width: 1024px;
  }
}

@media (max-width: 889px) {
  .custom-lg-name {
    display: none;
  }
}


@media (min-width: 1440px) {
  .custom-lg-name {
    position: absolute !important;
    font-size: 200px;
    color: #5142a3;
    font-weight: 900;
    text-align: center;
    position: relative;
    top: -210px;
  }
}

@media (min-width: 1006px) and (max-width: 1440px) {
  .custom-lg-name {
    position: absolute !important;
    font-size: 160px;
    color: #5142a3;
    font-weight: 900;
    text-align: center;
    position: relative;
    top: -330px;
  }
}

@media (min-width: 890px) and (max-width: 1005px) {
  .custom-lg-name {
    position: absolute !important;
    font-size: 140px;
    color: #5142a3;
    font-weight: 900;
    text-align: center;
    position: relative;
    top: -390px;
  }
}

.contact-hero-background-gradient {
  background: linear-gradient(45deg, #152235 0%, rgba(21, 34, 53, 0.2) 100%) no-repeat,
    url(./assets/ukroofing_hero.jpeg) center center no-repeat;
  background-size: cover;
  background-position: center center;
}

.products-hero-background-gradient {
  background: linear-gradient(45deg, #152235 0%, rgba(21, 34, 53, 0.2) 100%) no-repeat,
    url(./assets/ukroofing_hero.jpeg) center center no-repeat;
  background-size: cover;
  background-position: center center;
}

.scrollbar-visible {
  overflow-x: scroll;
}

.scrollbar-visible::-webkit-scrollbar:horizontal {
  height: 8px;
  margin-left: 6px;
  margin-right: 6px;
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid rgb(224, 222, 225);
  background-color: rgba(0, 0, 0, .2);
  
}

.scrollbar-visible::-webkit-scrollbar {
  -webkit-appearance: none;
}

/* Transitions from page on another see the App.js */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
